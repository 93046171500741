@font-face {
  font-family: 'SF Pro Rounded';
  src: url('../../../public/fonts/SF-Pro-Rounded-Regular.otf') format('opentype');
  /* Agrega variantes adicionales si las tienes, como italic, bold, etc. */
}

:root {
  --primary-dark : #3D3D3D;
  --primary-default : #696969;
  --primary-light :  #919191;
  --secondary-berry-dark  : #B33553;
  --secondary-berry-default : #C7506C;
  --secondary-berry-light : #EBC7D0;
  --secondary-berry-lighter : #F7E5E9;
  --secondary-bronze-dark : #9B896F;
  --secondary-bronze-default: #B6AA98;
  --secondary-bronze-light: #E5DED3;
  --secondary-bronze-lighter : #F1EFEB;
  --white : #fff;
  --grey-grey50: #F4F4F4;
  --grey-grey100: #E1E1E1;
  --grey-grey200: #CDCDCD;
  --grey-grey300: #B9B9B9;
  --grey-grey400: #A5A5A5;
  --grey-grey500: #919191;
  --grey-grey600:#7D7D7D;
  --grey-grey700:#696969;
  --grey-grey800: #555;
  --grey-grey900: #3D3D3D;
  --text-primary: #222;
  --text-secondary: #696969;
  --text-tertiary: #919191;
  --text--primaty--white : #FAFAFA;


}


body {
  font-family: 'SF Pro Rounded', sans-serif;
  margin: 0;
  padding: 0;

 
}



button{
  background: none;
  border: none;
}

/*Scroll Bar*/

::-webkit-scrollbar {
  width: 10px;
  z-index: 30px;
  height: 6px;
}
::-webkit-scrollbar-track {
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.1);
}
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.2);
}
::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.4);
}
::-webkit-scrollbar-thumb:active {
  background: rgba(0, 0, 0, 0.7);
}

h1,h2,h3,h4,h5,h6{
  color: var(--text-primary, #222);
  font-size: 22px !important;
  font-style: normal;
  font-weight: 400;
  line-height:29.26px;
  text-align: center;
}

/* Boxes */
/* 1 . Login,register,etc boxes  */
.PrincipalBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background-image: url("../assets/graficas/fondo.png");
  background-size: contain;
  background-repeat: repeat;
  background-position: center center;
  background-color: var(--grey-grey900);
  position: fixed;
  top: 0px;
  z-index: 100;
  gap: 16px;
}


.LoginForm {
  display: flex;
  flex-direction: column;
  padding: 16px;
  gap: 16px;
  width:90%;
  max-width: 400px;
}

.LoginVariantButton {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 12px;
  text-align: center;
  gap: 0px;
  border-radius: 8px ;
  border: 1px solid var(--secondary-bronze-default, #B6AA98);
  color:var(--white)

}

.AnimationLogoLayout{
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--white);
  position: fixed;
  top: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1000000;
}

.AnimationLogo{
  border-radius: 50%;
  background-color: var(--grey-grey100);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  transition: all 0.5s ease;
}

.LoginInput{
  background-color: var(--white);
  display: flex;
  justify-content: flex-start;
  height: 48px;
  padding: 8px 12px 8px 12px;
  gap: 8px;
  border-radius: 8px;
  border: 1px;
  border: 1px solid var(--grey-grey100, #E1E1E1);
  font-size: 16px;
  font-weight: 300;
  line-height: 16px;
  letter-spacing: 0.04em;
  text-align: left;
}

.LinkButtonDecoration{
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.04em;
  text-align: center;
  color: var(--secondary-bronze-light, #E5DED3);
  text-decoration: underline;
}


/* FINAL CSS  */

.App{
  background-color: var(--grey-grey100);
  width: 100%;
  min-height: 100vh;
  
}


.BottomBar{
width: 100vw;
height: 118px;
flex-shrink: 0;
position: fixed;
bottom: 0;
display: flex;
flex-direction: column;
justify-content: flex-end;
align-items: center;
z-index: 10;
}

.FAB{
display: flex;
width: 72px;
height: 72px;
justify-content: center;
align-items: center;
gap: 8px;
flex-shrink: 0;
border-radius: 100px;
background: var(--secondary-bronze-default);
position: fixed;
bottom:38px;
z-index: 11;
box-shadow: 0px -2px 16px 0px rgba(0, 0, 0, 0.15);
}

.menuWrap{
  display: flex;
  flex-direction: row;
  width: 360px;
  padding-top: 24px;
  justify-content: space-between;
  align-items: flex-start;
}

.ButtonGroup{
  display: flex;
  flex-direction: row;
  z-index: 12;
  gap: 16px;
  margin-bottom: 12px;
  margin-top: 12px;
}

.Shape{
  position: fixed;
  bottom: -1px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;

}

.Icon{
  color: #696969
}

.SelectedIcon{
  color :#B6AA98
}



.MenuItem{
  display: flex;
  width: 48px;
  height: 48px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
}




.MobileNavBarLayout{
  display: flex;
  padding: 8px 16px;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  width: 100vw;
  position: fixed;
  z-index: 11;
  justify-content: center;
  background-color: white ;
}

.InnerNav{
  display: flex;
  flex-direction: row ;
  width: 100%;
  max-width: 480px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  background-color: var(--white);
}


.MobileNavBarModal{
  width: 100vw;
  height: 100vh;
  background-color: rgba(0,0,0,0.5);
  position: fixed;
  z-index: 100000;
  justify-content: flex-end;
  align-items: center;
} 

.MobileNavBarMenu{
    justify-content: center;
    align-items: center;
    background-color: var(--white);
    border-radius: 25px 25px 0px 0px ;
    border-left: revert;
    width: 100vw;
    padding: 10px 0px 50px 0px;

}

MenuLink{
  padding: 10px;
  letter-spacing: 1.5px;
}

.MenuLinkCerrar{
  padding: 20px;
  letter-spacing: 1.5px;
  font-weight: bolder;
}

/* .MenuSeparation{
  background-color: var(--grey-grey100);
  height: 2px;
  border-radius: 1px;
  width: 85vw;
  margin: 10px 0px 10px 0px;
} */


.SliderMenuBackground{
display: flex;
height: 42px;
width: 100%;
flex-direction: column;
align-items: center;
align-self: stretch;
position: fixed;
top: 56px ;
background-color: white;
overflow: hidden;
z-index: 9;
}


.SliderMenuBackgroundNotFixed{
  display: flex;
  justify-content: flex-end;
  height: 42px;
  width: 100%;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  background-color: white;
  overflow: hidden;
  }
/* 
.SliderMenuCarousel div{
     max-width: 400px !important; 
} */
  

.SliderMenuCarousel{
  height: 43px;
  max-width: 1200px;
}



.SliderMenuItem{
  padding: 12px 0px;
  width: 70px;
  text-align: center;
  color: #696969;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 100%; /* 14px */
  letter-spacing: 1.96px;
  text-transform: uppercase;
  word-wrap: 'break-word'
}

.ActiveSlide{
  color: #9B896F;

}

.SliderRectangle{
  width: 71px;
  height: 4px;
  background-color: var(--secondary-bronze-default);
  position: absolute;
  bottom: -3px;
  border-radius: 4px;
}


.SliderRectangleNotFixed{
  width: 71px;
  height: 4px;
  background-color: var(--secondary-bronze-default);
  border-radius: 4px;
  margin-top: -5px;
}

.AlbumMenu{
  max-height: 42px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.Fade{
  width: 96px;
  height: 42px;
  flex-shrink: 0;
  position: absolute;
  top: 0;
  z-index: 10;
}
.FadeNotFixed{
  width: 96px;
  height: 42px;
  z-index: 10;
  position: relative;
  margin-top: -42px;
  
}

.LeftFade{
  background: linear-gradient(90deg, #FFF 12.5%, rgba(255, 255, 255, 0.00) 100%);
}

.RightFade{
  align-self: flex-end;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0.00) 0%, #FFF 86%); 
}


.FilterMenu{
  position: fixed;
  top:122px;
  right:0;
  z-index: 20;
  display: flex;
  flex-direction:column;
  justify-content: space-between;
  align-items: flex-end;
  margin-right:0;
}

.FilterButton{
  width: 48px;
  display: inline-flex;
  padding: 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 8px 0px 0px 8px;
  background: var(--white, #FFF);

  /* FAB-shadow */
  box-shadow: 0px -2px 16px 0px rgba(0, 0, 0, 0.15);
  /* height: 30px;
  width: 30px;
  margin: 5px 0px 5px 0px;
  display: flex;
  flex-direction: column;
  align-items: center; */
}
.FilterOptionsMenu{
  display: flex;
  flex-direction: column;
  max-width: 300px;
  padding: 8px 12px 4px 12px;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  border-radius: 12px;
  background: #FFF;
  /* menu */
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.10), 0px 9px 9px 0px rgba(0, 0, 0, 0.09), 0px 21px 13px 0px rgba(0, 0, 0, 0.05), 0px 37px 15px 0px rgba(0, 0, 0, 0.01), 0px 58px 16px 0px rgba(0, 0, 0, 0.00);
}



.FilterOptions{
  display: flex;
  min-height: var(--app-safari-address-bar-margin, 36px);
  padding: 8px 12px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  text-transform: capitalize;
  color: var(--text-primary, #222);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 100%; /* 14px */
  letter-spacing: 0.56px;
}

.Selected{
  color: #C7506C;
}

.SmallestButton{
  margin: 0;
  padding: 0;
}

.SmallHeader{
  display: flex;
  padding: 8px 0px 4px 8px;
  justify-content: space-between;
  gap: 8px;
  width: 100%;
  align-items: center;
  text-align: center;
  font-size: 14px;
  font-weight:  500;
  line-height: 100%; /* 14px */
  letter-spacing: 0.56px;
  color: var(--text-primary, #222);
  
}

.FilterOptionsFinal{
  display: flex;
  padding: 8px 12px;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  border-top: 1px solid var(--grey-grey100, #E1E1E1);
}

.FilterOptionsButton{
  display: flex;
  height: 36px;
  min-width: 96px;
  padding: 0px 14px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  flex: 1 0 0;
  border-radius: 32px;
  background:  #696969;
  color: #FFF;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 14px */
  letter-spacing: 0.56px;
}


.ShowDotsBar{
  width: 48px;
  position: fixed;
  top:198px;
  right:0;
  z-index: 8; 
  display: inline-flex;
  padding: 8px 4px;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  border-radius: 8px 0px 0px 8px;
  background: var(--white, #FFF);
  /* FAB-shadow */
  box-shadow: 0px -2px 16px 0px rgba(0, 0, 0, 0.15);
}

#ShowDotsButtons{
  background-color:rgba(0,0,0,0);
  display:flex;
  flex-direction:column;
  gap: 4px;
  padding:0px;
  margin:5px;
  width:25px;
  align-items:center;
  justify-content:center;
  border: none;
}


.PostBox{
  display: flex;
  flex-direction: column;
  justify-content:end;
  align-content: center;
  background-color:var(--white);
  border-radius:4px;
}

.PostBoxMedium{
  padding: 8px 8px 2px 8px;
}

.PostBoxLarge{
  padding: 12px 12px 2px 12px;

}

.CardInfoActionsMedium{
  display: flex;
  flex-direction: row;
  min-height:  36px;
  padding-left: 2px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.CardInfoUserName{
  display: flex;
  align-items: center;
  overflow: hidden;
  color: #222;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 100%; /* 16px */
  letter-spacing: 0.64px;
  text-transform: capitalize;
  display:flex;
  gap:10px;

}

.CardInfoPlace{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.CardInfoPlace svg{
  margin-bottom: 3px;
}



.CardInfoButtonsMedium{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 50px;
  align-items: center;
  margin: 6px;
}

.infinite-scroll-component__outerdiv{
width: 100%;
max-width: 100vw !important;
margin: 0px !important;
padding: 0px !important;
}

.infinite-scroll-component{
  display: flex;
  flex-direction: column;
  gap: 8px;
  overflow: hidden !important;
} 


/* Mansory */
.my-masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  /* margin-left: -30px; gutter size offset */
  width: auto;
  max-width: 100vw;
  gap: 8px  !important;
  margin: 0px 8px  !important ;
  justify-content: center;
}

.my-masonry-grid-large{
  justify-content: center !important;
}

.my-masonry-grid_column{
  width : unset !important;
}

.my-masonry-grid_column > div {
  margin: 8px 0px 0px 0px !important;

}

.CarouselDotBar{
  display: flex;
  padding: 2px 4px;
  justify-content: center;
  align-items: center;
}

.Mini{
  position: relative; 
  top: 0px;
  margin-top: -28px;
  background-color: rgba(0,0,0,0.25);
  width: 78px;
  height: 18px;
  align-self: center;
  border-radius: 16px;
  padding : 2px, 4px, 2px, 4px;
  
}
.DotWrapper{
  display: flex;
  justify-content: center;
  align-items: center;
}

.CarouselDot{
  display: flex;
  height: 24px;
  width: 24px;
  justify-content: center;
  align-items: center;
}

.Carousel{
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

}

.CarouselContainer{  
  display: flex;
  justify-content: flex-start !important;
  align-items: center;
}

.CarouselContainer div {
  max-width: unset !important;
}


.VideoContainer{
    display: flex;
    flex-direction: column;
    justify-content: flex-end; 
    align-items: flex-end;
    overflow: 'hidden';
    background-color: white;
}

.VideoTimeStamp{
  position: relative;
  bottom:-35px;
  color:rgba(255,255,255,0.7);
  background: rgba(34, 34, 34, 0.70);
  border-radius: 4px;
  font-size: small;
  width: 45px;
  height: 25px;
  text-align: center;
  padding: 2px 4px;
  margin: 0px 5px; 
  margin-top: -25px;
}

.ContentButton{
  border: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;

}

.FullPostLayoutMobile{
  display: flex;
  padding: 8px;
  flex-direction: column;
  align-items: center;
  background-color:#E1E1E1 ;
}

.FullPostBoxMobile{
   background-color: white;
   padding: 16px;
}


.FullPostHeader{
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.FullPostHeaderRowButton{
  display: flex;
  align-items: center;
  gap: 4px;
}

.FullPostHeaderButton{
  background : none;
  border:none;
  padding: 6px;
}


.FullPostHeaderFollowButton{
  display: flex;
  height: 36px;
  min-width: 96px;
  padding: 0px 14px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 32px;
  background:none;
  border: 1.5px solid var(--primary-light, #919191);
}

.FullPostHeaderFollowButtonVariant{
background: var(--primary-default, #696969);
color: white ;
border: none;
}


.FullPostContentCarousel{
  width: 100%;
  padding: 12px 31px ;
}


.FullPostContentHeader{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2px;
  margin-top: 12px;
}

.FullPostContentCreator{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}


.FullPostContentCreatorName{
  color: var(--text-primary, #222);
/* font-family: "SF Pro Rounded"; */
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height:29.26px 
}


.FullPostContentHeaderSub{
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 0px 0px 16px 0px;
}


.PlaceButton{
  color: var(--text-secondary, #696969);
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height:  15px; 
  background:none;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.PlaceButtonTitle{
  background:none;
  border: none;
  padding: 0px;
  color: var(--text-primary, #222);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height:29.26px;
  white-space: nowrap; /* Evita que el texto se divida en varias líneas */
  overflow: hidden; /* Oculta el texto que excede el contenedor */
  text-overflow: ellipsis; /* Muestra "..." al final del texto cortado */
}

.PlaceContentHeader{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    gap:3px;
  }


.FullPostDescription{
  color: var(--text-primary, #222);
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 21px;
  letter-spacing: 0.42px;
  padding: 16px 16px 16px 16px;
}

.PlaceContent{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 2px;
  padding: 0px 16px;
}

.PlaceContentSub{
  color: var(--text-secondary, #696969);
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 125%; /* 15px */
}



.RecomendationBar{
  display: flex;
  width: 100vw;
  padding: 0px 24px 24px 24px;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  background: var(--white, #FFF);
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 9;
  box-shadow: -1px -50px 34px -11px rgba(255,255,255,1);
  -webkit-box-shadow: -1px -50px 34px -11px rgba(255,255,255,1);
  -moz-box-shadow: -1px -50px 34px -11px rgba(255,255,255,1);
}

.RecomendationModalButton{
  display: flex;
  width: 100vw;
  padding: 24px 24px 24px 24px;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  background: var(--white, #FFF);
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 9;
}

.RecomendationModal{
  display: flex;
  width: 100vw;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  align-self: stretch;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 11;
}

.RecomendationModalBox{
  background-color: var(--white);
  padding: 15px 0px 0px 0px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
  border-radius:16px 16px  0px 0px;
  max-height: 30vh;
  overflow: hidden;
  box-shadow: 0px -2px 16px 0px rgba(0, 0, 0, 0.15);
  max-width: 650px;
}

.RecomendationModalRectangle{
  width: 125px;
  border-radius: 8px;
  background: var(--grey-grey100, #E1E1E1);
  min-height: 8px;
}

.RecomendationSpacer{
  width: 30px;
  height: 15vh;
}

.RecomendationBarTitle{
  color: var(--secondary-bronze-dark, #9B896F);
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 20px ;
  letter-spacing: 1.12px;
  text-transform: uppercase;
  display: flex;
  padding: 8px 0px;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  align-self: stretch;
}

.RecomendationBarProducts{
  display: flex;
  width: 100%;
  max-width: 100vw;
  display: flex;
  flex-direction: row;
  justify-content:flex-start;
  align-items: center;
}

.RecomendationBarProductsModal{
  display: flex;
  width: 100%;
  max-width: 650px;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 12px;
}

.RecomendationText{
  width: 100%;
  padding: 0px 16px;
  color: var(--text-secondary, #696969);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 150%;
  letter-spacing: 0.42px;
}


.RecomendationCarouselContainer{ 
  display: flex;
}


.SnackBar{
padding: 8px 12px 8px 16px;
border-radius: 12px;
gap: 20px;
background:var(--primary-dark);
font-size: 14px;
font-weight: 300;
line-height: 14px;
letter-spacing: 0.04em;
text-align: left;
color : var(--text--primaty--white);
position: fixed;
display: flex;
align-items: center;
justify-content: space-around;
margin: 12px;
z-index: 13px;
transition: opacity 0.2s ease-in; /* Agrega una transición suave */
opacity: 0; /* Comienza con una opacidad de 0 para que aparezca gradualmente */
}


.SnackBar.show {
  opacity: 1; /* Cuando la clase show está presente, muestra el Snackbar */
}


.FloatingButton{
  width: 100vw;
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  padding: 16px 16px 16px 16px;
  border: none;
}

.ProductButton{
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  border-radius: 12px;
  border: 1px solid var(--grey-grey50, #F4F4F4);
  background-color: var(--grey-grey300);
  background-position: center;
  background-size: cover;
  border:none;
  max-width: 468px;
  max-height: 468px;
}

.Divider{
  height: 1px;
  max-height: 1.5px;
  align-self: stretch;
  border-radius: 2px;
  background: var(--grey-grey100, #E1E1E1);
  margin: 8px 0px 0px 0px ;
}

.Divider-Smaller{
  margin: 6px 0px 6px 0px !important;
}

.RecomendationCheckIn{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: var(--Text-Primary-Black-Text, var(--Text-primaryBlackText, #0F1709));
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 21px;
  letter-spacing: 0.42px;
  gap:8px;
  padding:8px 0px;
  margin: 0px 12px;
}

.SmallCheck{
  display: flex;
  width: var(--size-beta-size-medium, 20px);
  height: var(--size-beta-size-medium, 20px);
  min-width: 20px;
  max-width: 20px;
  min-height: 20px;
  max-height: 20px;
  padding: 0px;
  border-radius: 6px;
  border: 1.5px solid var(--Greyscale-grey05, #BFBFBF);
  background: var(--secondary-bronze-lighter, #F1EFEB);
  }

.FullModal{
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 14;
  top: 0;
  left: 0;
}

  .ShareModalOverlay{
    display:flex;
    justify-content:space-around;
    align-items: center;
    margin:10px;
    font-size:12px;
    display: flex;
    padding: 12px 8px 12px 16px;
    gap: 8px;
    border-radius: 12px;
    background: #FFF; 
    box-shadow: 0px -2px 16px 0px rgba(0, 0, 0, 0.15);
    position: fixed;
    z-index: 15;
  }



  .ShareModalContent{
    display:flex;
    justify-content:space-around;
    align-items: flex-start;
    gap: 16px;
    animation-name: modalPop; /* Nombre de la animación */
    animation-duration: 0.2s; /* Duración de la animación */
    animation-timing-function: ease-out; /* Función de temporización */
  }



  .ShareModalButton{
    display: flex;
    padding: 6px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 99px;
    border: 1px solid  #F4F4F4;
    background:  #FFF;
    font-size: 7px;
  }


.CloseModal{
  display: flex;
  padding: 6px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
}


.BottomModal{
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 15;
  background: rgba(34, 34, 34, 0.6);
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.BottomModalOpen{
  animation-name:disolveIn ;
  animation-duration: 0.2s; /* Duración de la animación */
  animation-timing-function: ease-out; /* Función de temporización */
}
.BottomModalClose{
  animation-name:disolveOut ;
  animation-duration: 0.2s; /* Duración de la animación */
  animation-timing-function: ease-out; /* Función de temporización */

}

.BottomModalContent{
  display: flex;
  padding: 0px 16px 16px 16px;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  border-radius: 16px 16px 0px 0px;
  background: var(--white, #FFF); 
}

.BottomModalContentOpen{
  animation: slideIn 0.4s ease forwards;
}

.BottomModalContentClose{
  animation: slideOut 0.4s ease forwards;
}



.BotomModalRow{
  display: flex;
  width: 100%;
  justify-content: space-around;
  align-items: center;
  gap: 8px;
}

.BottomModalWishlist{
  color: var(--primary-dark, #3D3D3D);
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 100%; /* 16px */
  letter-spacing: 0.64px;
  gap:12px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.PostMiniPreview{
  width: 80px;
  height: 80px;
  background-position: center;
  background-size: cover;

}

.BottomModalIcon{
  padding: 8px;
}


.BottomModalButton{
  display: flex;
  height: 40px;
  min-width: 96px;
  padding: 0px 16px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  align-self: stretch;
  border-radius: 32px;
  border: 1.5px solid var(--primary-light, #919191);
  background: none;
}

.BottomModalRectangle{
  width: 125px;
  height: 8px;
  border-radius: 8px;
  background: var(--grey-grey100, #E1E1E1);
  margin: 20px 0px;
}

.WishlistProduct{
  display: flex;
  padding: 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 99px;
  border: 1px solid var(--grey-grey50, #F4F4F4);
  background: var(--white, #FFF);
  margin-bottom: -38px;
  position: relative;
  z-index: 3;
}



.ProductModal{
  width: 100vw;
  height: 100%;
  position: fixed;
  top:0px;
  left: 0px;
  z-index: 13;
  background: rgba(34, 34, 34, 0.6);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}

.ProductModalContent{
  background-color: var(--white);
  width: 100%;
  display: flex;
  padding: 40px 16px 16px 16px;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  border-radius: 12px 12px 0px 0px;
  background: #FFF;
  max-width: 650px;
}

.ProductModalRectangle{
  width: 125px;
  height: 8px;
  border-radius: 8px;
  position: relative;
  margin-top: -24px;
  background: var(--grey-grey100, #E1E1E1);

}

.ProductBox{
 display: flex;
 flex-direction:column;
 justify-content: center;
 align-items: center;
 width: 100%;
 gap:12px;

}


.ProductPicture{
  background-color: var(--grey-grey300);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  max-width: 300px;
  max-height: 300px;

}
.ProductInfo{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 16px 0px 0px 0px;
  }



 .ProductName{
  color: var(--text-primary, #222);
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: 100%;
  letter-spacing: 1.4px;
  white-space: nowrap; /* Evita que el texto se divida en varias líneas */
  overflow: hidden; /* Oculta el texto que excede el contenedor */
  text-overflow: ellipsis; /* Muestra "..." al final del texto cortado */
 }


 .ProductPicture img{
  height:unset !important;
 }

 .ProductDescription{
  color: var(--text-secondary, #696969);
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 100%; /* 16px */
  letter-spacing: 0.64px;
  margin-bottom: -16px;
 }

 .ProductBar{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
 }

 .ProductBarRight{
  display: flex;
  gap: 4px;
 }


 .ProductBarButton{
  display: flex;
  background: none;
  height: 40px;
  min-width: 96px;
  padding: 0px 16px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 32px;
  border: 1.5px solid var(--primary-light, #919191);
  color: var(--primary-default, #696969);
 
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 14px */
  letter-spacing: 0.56px;
}

 .ButtonFilled{
  display: flex;
  height: 40px;
  min-width: 96px;
  padding: 0px 16px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 24px;
  border: none;
  background: var(--secondary-bronze-default, #B6AA98);
  color: var(--white, #FFF);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%; /* 15px */
  letter-spacing: 0.84px;
  text-transform: uppercase;
 }
 

/* Animations */

@keyframes slideIn {
  from {
      transform: translateY(100%);
      opacity: 0;
  }
  to {
      transform: translateY(0);
      opacity: 1;
  }
}

@keyframes slideOut {
  from {
    transform: translateY(0);
    opacity: 1;
  }
  to {
    transform: translateY(100%);
      opacity: 0;
  }
}

@keyframes disolveIn {
  0% {
    opacity: 0; /* Completamente transparente */
  }
  100% {
    opacity: 1; /* Totalmente visible */
  }
}


@keyframes disolveOut {
  0% {
    opacity: 1; /* Completamente transparente */
  }
  100% {
    opacity: 0; /* Totalmente visible */
  }
}

  /* Definir la animación */
@keyframes modalPop {
    0% {
      transform: scale(0.5); /* Inicia pequeño */
      opacity: 0; /* Completamente transparente */
    }
    100% {
      transform: scale(1); /* A tamaño normal */
      opacity: 1; /* Totalmente visible */
    }
  }


  .FeedSpacer{
    height: 105px;
    width: 30px;
  }

  .ProfileLayout{
    padding: 8px;
    display: flex;
    width: 100%;
    align-items:  center;
    flex-direction: column;
    
  }
  
  .ProfileBox{
    background-color: var(--white);
    min-height: 70vh;
    display: flex;
    width: 100%;
    min-width: 344px;
    max-width: 650px;
    padding: 16px;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    border-radius: 4px;
  }

  .ProfileHeaderBar{
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    }

  .ProfileHeaderBarRight{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 4px;
  }


  .ProfileUserInfoBox{
    display: flex;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
  }

  .ProfileUserImg{
    background-color: var(--grey-grey300);
    display: flex;
    width: 104px;
    height: 104px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    border-radius: 156px;
    overflow: hidden;
  }

  .NameLogo{
    min-width:  104px;
    min-height: 104px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: -4px;
  }

  .ProfileInfo{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    flex: 1 0 0;
  }

  .ProfileName{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    align-self: stretch;

  }

  .ProfileFullName{
    color: var(--text-primary, #222);
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 133%;
    display: flex;
    align-items: center;
    width: 100%;
    white-space: nowrap; /* Prevents text from wrapping to a new line */
    overflow: hidden; /* Hides any overflowing text */
    text-overflow: ellipsis; /* Displays an ellipsis (...) when text overflows */
}

.ProfileBagde{
    height: 20px;
    width: 20px;
  }

.ProfileData{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    align-self: stretch;
  }


.ProfileDataRow{
  display: flex;
  align-items: center;
  gap: 4px;
  color: var(--text-secondary, #696969);
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 125%; /* 15px */
}

.ProfileSocial{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    flex: 1 0 0;
}

.ProfileCount{
  display: flex;
  padding: 0px 12px;
  flex-direction: column;
  align-items: center;
  color: var(--text-tertiary, #919191);
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 125%; /* 15px */
}

.ProfileCountNumber{
  color: var(--text-primary, #222);
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 20px */
  letter-spacing: 1.4px;
}

.ProfileSocials{
  display: flex;
  align-items: center;
  gap: 16px;
}

.ProfileSocialsButtons{
    display: flex;
    align-items: flex-start;
    gap: 16px;
}

.ProfileSocialButton{
  border-radius: 99px;
  border: 1px solid var(--grey-grey50, #F4F4F4);
  background: var(--white, #FFF);
  display: flex;
  padding: 12px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  width: 44px;
  height: 44px;
}


.ProfileDescription{
  display: flex;
  align-items: center;
  width: 100%;
  gap: 20px;
  align-self: stretch;
  color: var(--text-primary, #222);
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 150%; /* 21px */
  letter-spacing: 0.42px;
}


.RecentPostBox{
  padding: 8px 0px;
  gap: 8px;
}

.RecentPost{
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: var(--grey-grey300);
}

.RecentPostButton{
  display: flex;
  height: 44px;
  padding: 0px 24px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  align-self: stretch;
  border-radius: 24px;
  background: var(--secondary-bronze-default, #B6AA98);
  color: var(--white, #FFF);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 125%; /* 17.5px */
  letter-spacing: 0.98px;
  text-transform: uppercase;
  border: none;
}


.ConfigModal{
  display: flex;
  min-width: 164px;
  padding: 4px 0px;
  flex-direction: column;
  align-items: flex-start;
  position: absolute;
  right: 22px;
  top: 123px;
  border-radius: 12px;
  background: #FFF;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.10), 0px 9px 9px 0px rgba(0, 0, 0, 0.09), 0px 21px 13px 0px rgba(0, 0, 0, 0.05), 0px 37px 15px 0px rgba(0, 0, 0, 0.01), 0px 58px 16px 0px rgba(0, 0, 0, 0.00);
}

.ConfigOptionsButton{
  display: flex;
  min-height: var(--app-safari-address-bar-margin, 36px);
  padding: 8px 12px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  color: var(--text-primary, #222);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 100%; /* 14px */
  letter-spacing: 0.56px;
  background: none;
  border:none;
}

.ProfileSocialsCounter{
  display: flex;

}

.MyProfileSection{
  display: flex;
  align-items: flex-start;
  gap: 16px;
}

.ProfileInviteButton{
  display: flex;
  height: 44px;
  padding: 0px 24px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 24px;
  background: var(--secondary-bronze-default, #B6AA98);
  color: var(--white, #FFF);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 125%; /* 17.5px */
  letter-spacing: 0.98px;
  text-transform: uppercase;
  border: none;
}

.ProfileEditTile{
  color: var(--primary-default, #696969);
text-align: center;
font-size: 16px;
font-style: normal;
font-weight: 300;
line-height: 100%; /* 16px */
letter-spacing: 0.64px;
}

.ProfileImageChange{
  display: flex;
  padding: 8px 12px;
  align-items: center;
  gap: 10px;
  justify-content: space-between;
  border-radius: 12px;
  background: var(--grey-grey50, #F4F4F4);
}

.ProfileMiniImage{
  display: flex;
  min-width: 64px;
  height: 64px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 156px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: var(--grey-grey500);
  

}

.ProfileImageFlex{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
  align-items: center;
}

.ProfileMiniFullname{
  color: var(--text-primary, #222);
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 100%; /* 16px */
  letter-spacing: 0.64px;
  white-space: nowrap !important;  /* Prevents text from wrapping to a new line */
  overflow: hidden; /* Hides any overflowing text */
  text-overflow: ellipsis; /* Displays an ellipsis (...) when text overflows */

}

.ProfileMiniPhoto{
  display: flex;
  height: 36px;
  min-width: 96px;
  padding: 0px 14px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 32px;
  background: var(--primary-default, #696969);
  color: var(--white, #FFF);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 14px */
  letter-spacing: 0.56px;
  border: none ;
  white-space: nowrap; /* Prevents text from wrapping to a new line */
  overflow: hidden; /* Hides any overflowing text */
  text-overflow: ellipsis; /* Displays an ellipsis (...) when text overflows */

}

.ProfileEditBody{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  align-self: stretch;
}

.ProfileInputBox{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  color: var(--text-primary, #222);
  font-size: 14px;
  font-style: normal;
  font-weight: bold;
  line-height: 100%; /* 14px */
  letter-spacing: 0.56px;
  font-family: SF Pro Rounded;


}



.ProfileInput{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  min-height: 56px;
  max-height: 80px;
  align-items: flex-end;
  gap: 8px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid var(--grey-grey100, #E1E1E1);
  background: var(--white, #FFF);
}

.ProfileEditContador{
  color: var(--text-tertiary, #919191);
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 125%; /* 15px */
  margin-top: -22px;
  background-color: white;
}

.form-control{
  border : unset !important;
  box-shadow : unset !important ;
  
  
}

option{
  color: var(--text-secondary, #696969);
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 100%; /* 14px */
  letter-spacing: 0.56px;
}

.css-b62m3t-container{
  width: 100%;
}

.css-13cymwt-control{
  border: none !important;
}

.rmsc .dropdown-container:focus-within{
  box-shadow: none !important;
}
.css-1u9des2-indicatorSeparator{
  width: 0px !important;

}

.css-1fdsijx-ValueContainer{
  padding: 0px  !important;
}

.css-qbdosj-Input{
  padding: 0px !important;
  margin: 0px !important;
}


.css-t3ipsp-control{
  border: none !important;
  box-shadow: none !important;  
}


textarea.form-control {
  color: var(--text-primary, #222);
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 150%; /* 21px */
  letter-spacing: 0.42px;
}

input.form-control{
  color: var(--text-tertiary, #919191);
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 100%; /* 14px */
  letter-spacing: 0.56px;
  padding: unset;
}

.form-control:focus{
  border : unset !important;
  box-shadow : unset !important ;
}

.ProfileEditSocials{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  color: var(--text-primary, #222);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; /* 14px */
  letter-spacing: 0.56px;
}

.LongText{
font-family: SF Pro Rounded;
font-size: 14px;
font-weight: lighter;
line-height: 14px;
letter-spacing: 0.04em;
text-align: left;
color: var(--text-secondary);
padding: 0px 10px;
}

.ForgotPassLink{
  font-family: SF Pro Rounded;
  font-size: 12px;
  font-weight: 300;
  line-height: 15px;
  text-align: left;
  text-decoration: underline;
  padding: 10px;

}

.ProfileEditBannerIncomplete{
  display: flex;
  padding: 8px 12px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 8px;
  background: var(--secondary-berry-lighter, #F7E5E9);
  color: var(--secondary-berry-dark, #B33553);
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 125%; /* 15px */
}


.ProfileEditBannerIcon{
  min-width: 20px;
  min-height: 20px;
}

.ProfileEditBannerComplete{
  display: flex;
  padding: 8px 12px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 8px;
  background: var(--secondary-bronze-lighter, #F1EFEB);
  color: var(--text-primary, #222);
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 125%; /* 15px */
}

.ProfileSocialsInput{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 9px;
    align-self: stretch;
  }

.ProfileSocialsRow{
  display: flex;
  align-items: center;
  width: 100%;
  gap: 8px;
}

.ProfileInputLine{
  display: flex;
  padding: 8px 12px;
  align-items: flex-start;
  gap: 8px;
  flex: 1 0 0;
  border-radius: 8px;
  border: 1px solid var(--grey-grey100, #E1E1E1);
  /* background: var(--white, #FFF); */

}


.UploadFotoLayout{
  display: flex;
  padding: 8px;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
}

.UploadContentBox{
  display: flex;
  justify-content: center;
  height: 342px;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.PhotoPlacement{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: #F4F4F4;
  width: 260px;
}


.UploadButton{
  display: flex;
  height: 36px;
  min-width: 96px;
  padding: 0px 14px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 32px;
  background: var(--primary-default, #696969);
 
}

.UploadLabel{
  color: var(--white, #FFF);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 14px */
  letter-spacing: 0.56px;
}

.FloatingCarouselButton{
  display: flex;
  padding: 12px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  position: absolute;
  align-self: center;
  top: 400px;
  margin-right: 260px;
  border:none;
  border-radius: 99px;
  background: rgba(34, 34, 34, 0.70);
  /* FAB-shadow */
  box-shadow: 0px -2px 16px 0px rgb(182, 182, 182);

}

.FloatingCarouselButtonCenter{
  display: flex;
  padding: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: center;
  position: relative;
  border:none;
  border-radius: 99px;
  background: rgba(34, 34, 34, 0.70);
  /* FAB-shadow */
  box-shadow: 0px -2px 16px 0px rgba(0, 0, 0, 0.15);
  margin-top: -60px;
}

.FloatingCarouselButtonCorner{
  display: flex;
  padding: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  position: relative;
  border:none;
  border-radius: 99px;
  background: rgba(34, 34, 34, 0.70);
  /* FAB-shadow */
  box-shadow: 0px -2px 16px 0px rgba(0, 0, 0, 0.15);
  margin-top: -35px;
  margin-right: 5px;
}

.ContentCarouselImage{
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.ProgressBarContainer{
  width: 95%;
  align-self: center;
  position: relative;
  top:-1px;
  margin-top: -3px;
  height: 3px;
  background-color: rgba(61, 61, 61, 0.7);
  border-radius: 4px;
}

.ProgressBar{
  width: 100%;
  height: 3px;
  background-color: #919191;
  border-radius: 4px;
}

.PostForm{
  width:100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.PostFormMultiSelect{
  display: flex;
  padding: 8px 12px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid var(--grey-grey100, #E1E1E1);
  background: var(--white, #FFF);
  width: 100%;
  flex-wrap: wrap;
}


.rmsc .select-item{
  display: flex !important;
}

.rmsc {
  width:100%;
  border: none !important;
  color: var(--text-secondary, #696969);
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 100%; /* 14px */
  letter-spacing: 0.56px;
  height: unset !important;
}
.rmsc .dropdown-heading .dropdown-heading-value:focus-within {
  border : unset !important
}

.rmsc .dropdown-container{
  border: none !important;
  padding:0 !important;
  height: unset !important;
}

.rmsc .dropdown-heading{
  height: unset !important;
}


.dropdown-content{
  z-index: 16 !important;
}



.pac-container{
  border-radius: 10px;
  padding: 10px;
  gap:8px;
}

.pac-item{
  background:  none;
  color: var(--text-secondary, #696969);
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 100%; /* 14px */
  letter-spacing: 0.56px;
  border: none !important;
  }

.pac-item:hover{
    background-color: #CDCDCD !important;
    color: var(--text-secondary, #696969);
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 100%; /* 14px */
    letter-spacing: 0.56px;
    border: none !important;
}

.rti--container{
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  color: var(--text-secondary, #696969);
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 100%; /* 14px */
  letter-spacing: 0.56px;
  border: none !important;
  height: unset !important;
  background: none !important;
}

.rti--container:focus-within{
  color: var(--text-secondary, #696969);
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 100%; /* 14px */
  letter-spacing: 0.56px;
  border: none !important;
  height: unset !important;
  background: none !important;
  box-shadow: none !important;
}





.rti--input{
  width: 100% !important;
}

.rti--input:focus-within{
  border: none !important;
  height: unset !important;
  background: none !important;
}

.HelpText{
  color: var(--text-secondary, #696969);
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 125%; /* 15px */
}



.pac-icon{
  visibility: hidden;
  margin-right: -10px;
}


.FormOptions{
  display: flex;
  align-items: center;
  gap: 4px;
  flex: 1 0 0;
  color: var(--text-primary, #222);
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 100%; /* 14px */
  letter-spacing: 0.56px;
}


.SearchLayout{
  display: flex;
  padding: 8px;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  background-color: var(--dirtywhite);
}

.SearchBox{
  display: flex;
  min-height: 750px;
  padding: 16px;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  align-self: center;
  border-radius: 4px;
  background: #FFF;
  max-width: 650px;
  width: 100%;
}


.SearchWrapper{
  position: relative; 
  z-index: 2;
  max-width: 618px;
}

input {
  margin: 0px 10px 0px 10px;
  color: var(--lightgrey);
  font-size: 12;
  border: none;
  background-color: rgba(0, 0, 0, 0);
  width: 90%;
}

input:focus-visible{
  border: none !important;
  outline: none !important;
}


.SearchSection{
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  color: var(--text-secondary, #696969);
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 100%; /* 14px */
  letter-spacing: 0.56px;
  }

  .BannerSectionTitle{
    width: 100%;
    text-align: left;
  }

  .MagazineCarousel{
    border-radius: 8px;
    overflow: hidden;
    max-width: 600px;
}


.BannerContainer{
  border-radius: 12px;
  display: flex;
  align-items: center;
  max-width: 600px;
  max-height: 200px;
}

.BannerTitle{
  padding: 8px;
  height: 28px;
  position: relative;
  margin-bottom: -10px;
  color:  #696969;
  font-size: 12px;
  font-style: 700;
  font-weight: 300;
  line-height: 100%; /* 14px */
  letter-spacing: 0.56px;
  white-space: nowrap; /* Evita que el texto se envuelva a la siguiente línea */
  overflow: hidden; /* Oculta cualquier parte del texto que no quepa dentro del contenedor */
  text-overflow: ellipsis; /* Agrega puntos suspensivos (...) para indicar que el texto fue truncado */
}

.ProductsTrending{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
  color: var(--text-secondary, #696969);
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 100%; /* 14px */
  letter-spacing: 0.56px;
}

.ProductosFeed{
  display: flex;
  justify-content: center;
  align-items: flex-start;
  align-content: flex-start;
  gap: 12px;
  align-self: stretch;
  flex-wrap: wrap;
}

.CloseMiniButton{
  background: none ;
  border: none;
  padding: 0px;
}

.SearchTabs{
  display: flex;
  justify-content: center;
  align-items: flex-start;
  color: var(--primary-default, #696969);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 100%; /* 14px */
  letter-spacing: 1.96px;
  text-transform: uppercase;
  position: fixed;
  top: 56px;
  width: 100%;
  left: 0px;
  background-color: white;
  z-index: 10;
}


.SearchTabsFixed{
  display: flex;
  justify-content: center;
  align-items: flex-start;
  color: var(--primary-default, #696969);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 100%; /* 14px */
  letter-spacing: 1.96px;
  text-transform: uppercase;
  background-color: white;
}


.SearchTab{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 110px;
}

.TitleTab{
  display: flex;
  padding: 12px 10px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 8px;
  font-weight: 500;
}

.TitleTabActive{
  color:  #B6AA98 !important;
}

.TabRectangle{
  width: 100%;
  height: 4px;
  padding: 0px;
  position: relative;
  border-radius: 4px;
  background: var(--secondary-bronze-default, #B6AA98);
}


.UsersFeed{
  display: flex;
  width: 100%;
  padding: 8px;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  align-self: stretch;
}

.UserRow{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  align-self: stretch;
}

.UserInfo{
  display: flex;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  color: var(--text-primary, #222);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 133%; /* 21.28px */
}

.ProfileMiniSubtitles{
  color: var(--text-secondary, #696969);
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 125%; /* 15px */
  padding: 2px 0px;
}


.ModalMenu{
  width: 100%;;
  height: 100%;
  position: fixed;
  top:0;
  background: rgba(34, 34, 34, 0.50);
  z-index: 16;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.ModalBox{
  display: flex;
  max-height: 600px;
  padding: 64px 24px 32px 24px;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  border-radius: 16px 16px 0px 0px;
  background: var(--white, #FFF);
}

.ModalLink{
  display: flex;
  padding: 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  color: var(--primary-default, #696969);
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: 100%; /* 20px */
  letter-spacing: 1.4px;
}

.MainModalMenu{
  display: flex;
  padding: 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  color: var(--primary-dark, #3D3D3D);
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%; 
  letter-spacing: 1.4px;
}


.BottomModalRectangle.Modal{
  position : relative;
  margin-top: -48px;

}

.BenefitLayout{
  display: flex;
  min-height: 750px;
  padding: 8px;
  flex-direction: column;
  align-items: center;
  width: 100vw;
}

.BenefitsBox{
  display: flex;
 
  max-width: 650px;
  padding: 16px;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  border-radius: 4px;
  background: #FFF;
  color: #696969;
  min-height: 90vh;
  width: 100%;
}


.BenefitBox{
  display: flex;
  gap: 16px;
  padding: 20px 12px;
  flex-direction: column;
  align-items: center;
  border-radius: 12px;
  background: #FFF;
  border-radius: 12px;
  background: #FFF;
  /* FAB-shadow */
  box-shadow: 0px -2px 16px 0px rgba(0, 0, 0, 0.15);
  width: 100%;
}

.BenefitBoxTop{
  display: flex;
  align-items: flex-start;
  gap: 8px;
  width: 100%;
}

.BenefitsFeed{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  background-color: white;
  padding: 16px;
}

.BenefitLogoCategorie{
  min-height: 44px;
  min-width: 44px;
  display: flex;
  padding: 10px;
  align-items: flex-start;
  gap: 8px;
  border-radius: 56px;
  background: var(--secondary-bronze-lighter, #F1EFEB);
}
.BenefitTitle{
  color: var(--text-primary, #222);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 27px */
}

.BenefitDescription {
  color: var(--text-secondary, #696969);
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 125%; /* 15px */
}


.BenefitBoxBottom{
  display: flex;
  justify-content: space-around;
  align-items: center;
  align-self: stretch;
  margin-top: 8px;
  gap: 10px;
  flex-wrap: nowrap;
}


.BenefitButton {
  display: flex;
  height: 36px;
  min-width: 96px;
  padding: 0px 14px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  color: var(--white, #FFF);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 14px */
  letter-spacing: 0.56px;
  border: none;
  border-radius: 32px;
  background: var(--primary-default, #696969);
 }

 .BenefitUpload{
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
  width: 100%;
 }


.CounterBox{
  display: flex;
  align-items: center;
  gap: 4px;
  flex: 1 0 0;

}

.CounterTitle{
  color: var(--text-secondary, #696969);
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 125%; /* 15px */
  width: 44px;
}

.CounterClock{
  display: flex;
  padding: 4px 8px;
  align-items: flex-start;
  gap: 4px;
  border-radius: 4px;
  background: var(--grey-grey50, #F4F4F4);
}

.NumberCounter{
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--text-secondary, #696969);
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 133%; 
}

.CounterSmallText{
  color: var(--text-tertiary, #919191);
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 133%;
  letter-spacing: 0.6px;
}


.NotificationButton{
  display: flex;
  padding: 16px 20px 16px 12px;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 8px;
  align-self: stretch;
  border-radius: 12px;
  border: 1px solid var(--grey-grey50, #F4F4F4);
}

.NotificationBox{
  display: flex;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  border-radius: 12px;
}

.NotificationImage{
  display: flex;
  padding: 10px;
  align-items: flex-start;
  gap: 8px;
  border-radius: 56px;
  background: var(--secondary-bronze-lighter, #F1EFEB);
}

.NotificationInfo{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;
  flex: 1 0 0;
}


.NotificationInfoTop{
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.NotificationTitle{
  color: var(--text-primary, #222);
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 125%; /* 15px */
}


.NotificationDate{
  color: var(--text-tertiary, #919191);
  font-size: 11px;
  font-style: normal;
  font-weight: 300;
  line-height: 125%; /* 13.75px */

}

.NotificationInfoBottom{
  color: var(--text-secondary, #696969);
font-size: 14px;
font-style: normal;
font-weight: 300;
line-height: 150%; /* 21px */
letter-spacing: 0.42px;
text-align: start;

}



.NotificationBadge{
  margin-left: -20px;
  position: relative;
  left: 12px;
  top: -15px;
  
}


.ModalCenteredLayout {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99; /* Ajusta el z-index según sea necesario */
}

.ModalCenteredBox {
  position: relative;
  max-width: 350px; 
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);

  display: flex;
  padding: 32px 32px 24px 32px;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  border-radius: 12px;
  background-color: #FFF;
}



.ModalTitle{
  color: var(--primary-default, #696969);
  text-align: center;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 133%; /* 29.26px */
}

.ModalBody{
  color: var(--text-secondary, #696969);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 150%; /* 21px */
  letter-spacing: 0.42px;
}


.ModalButton{
  display: flex;
  height: 44px;
  padding: 0px 24px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 24px;
  background: var(--secondary-bronze-default, #B6AA98);
  color: var(--white, #FFF);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 125%; /* 17.5px */
  letter-spacing: 0.98px;
  text-transform: uppercase;
}

.ModalSecondaryButton{
  color: var(--secondary-bronze-dark, #9B896F);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 125%; /* 17.5px */
  letter-spacing: 0.98px;
  text-transform: uppercase;
  text-decoration:underline;
}


.ModalCloseButton{
  display: flex;
  padding: 6px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  position: relative;
  margin-top: -48px;
  align-self:flex-end ;
  margin-right: -30px;
  top: 22px;
}

.ModalPhotoBox{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.ModalPhotoDivider{
  width: 314px;
  height: 3px;
  background: var(--secondary-bronze-light, #E5DED3);
  position: relative;
  top: -99px ;
  margin-top: -3px;
}


.ModalPhoto{
  position: relative;
  z-index: 12;
  border-radius: 172px;
  display: flex;
  width: 198px;
  height: 198px;
  max-width: 280px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background-size: cover;
  background-position: center; 
  background-repeat: no-repeat; 
}


.NotificationIconAndBagde{
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.StatusBadge{
  position: relative;
  margin-top: -20px;
}

.UploadProfilePictureBox{
  width:280px;
  height:280px;
  background-color:#F4F4F4;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 10px;
  overflow: hidden;
  gap: 10px;
  background-size: cover;
  background-position: center;
}



.css-187mznn-MuiSlider-root{
  color: #B33553 !important;
}


.css-eg0mwd-MuiSlider-thumb.Mui-active{
  box-shadow: 0px 0px 5px 5px rgba(179, 53, 83, 0.5) !important; /* Box-shadow con color semitransparente */
}

.ProductsContainer{
  display: flex;
  align-items: center;
  justify-content: center;
  width:100%;
}

.ProductsBox{
  display: flex;
  padding: 12px 12px;
  justify-content: space-evenly;
  align-items: flex-start;
  gap: 12px;
  flex-wrap: wrap;
  max-width: 1000px;
  width:100%;
}

.OzLogo{
  position: relative;
  margin-top: -40px;
  width: 100%;
  display: flex;
  left: -30%;
  opacity: 0.7;
  }

.ProductButtonBox{
    padding: 0;
    margin: 0;
    border-radius: 12px ;
    border: 1px solid var(--grey-grey100);
    background-color: white;
    overflow: hidden;
}

.PostCarouselRow{
  display: flex;
  flex-direction: row;
  padding: 10px 5px;
}

.PostScreenBorder{
  box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.1), /* Sombra superior */
            0px 2px 10px rgba(0, 0, 0, 0.4); /* Sombra inferior */
}


.ProductMiniTitle{
  width: 100%;
  color: var(--text-secondary, #696969);
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 100%; /* 16px */
  letter-spacing: 0.64px;
}


.BenefitBadgeRed{
  color: var(--secondary-berry-default, #C7506C);
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%; /* 12.5px */
  display: flex;
  padding: 4px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 4px;
  background: var(--secondary-berry-lighter, #F7E5E9);
  position: relative;
  margin-top: -10px;
  align-self: flex-end;
}

.BenefitBadgeGrey{
  color: var(--grey-grey700);
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%; /* 12.5px */
  display: flex;
  padding: 4px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 4px;
  background: var(--grey-grey100);
  position: relative;
  margin-top: -10px;
  align-self: flex-end;
}

.BenefitBadgeGreen{
  color: #576B4C;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%; /* 12.5px */
  display: flex;
  padding: 4px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 4px;
  background: #E2F1DB;
  position: relative;
  margin-top: -10px;
  align-self: flex-end;
}


.BenefitButtonGroup{
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: -26px;
  top:-5px;
  gap:5px;
}

.BenefitMiniButton{
  color: var(--secondary-bronze-default);
  size: 11px;
}


.LinkButton{
  color: var(--secondary-bronze-default);
}

.LinkButtonGrey{
  color: var(--grey-grey200);
}


.BenefitUploadAndComments{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 6px;
  font-size: 12px;
  color: var(--text-tertiary);
}

.BenefitInputBox{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  color: var(--text-primary, #222);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; /* 14px */
  letter-spacing: 0.56px;
  width: 100%;
  max-width: 100%;
}



.BenefitInput{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  min-height: 56px;
  max-height: 80px;
  align-items: flex-end;
  gap: 8px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid var(--grey-grey100, #E1E1E1);
  background: var(--white, #FFF);
}

.BenefitEditContador{
  color: var(--text-tertiary, #919191);
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 125%; /* 15px */
  position: relative;
  right:26px;
  margin-top: -22px;
  background-color: white;
}


.BadgedNumber{
  display: flex;
  justify-content: center;
  align-content: center;
  position: relative;
  margin-top: -20px;
  margin-left: 16px;
  width: 16px;
  height:16px;
  border-radius: 56px;
  gap: 8px;
  background-color: var(--secondary-berry-default);
  font-size: 9px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: -0.02em; 
  color: var(--white);
}

input[type="checkbox" i] {
  width: unset !important;
}


.SamplePostCarousel div{
  max-width: unset !important;
}


.NoContentMessage{
  width: 95%;
  padding: 0px 16px;
  color: var(--text-secondary, #696969);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 150%;
  letter-spacing: 0.42px;
  padding: 32px;
}

.LoadingMessage{
  color: var(--secondary-bronze-default) !important;
}

.LoadingMessage img{
  width: 50px;
  height:50px;
}


.AlbumContainer{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}


.LoadingModal{
  height: 100vh;
  width: 100vw;
  position: fixed;
  background-color: rgba(255, 255, 255, 0.6);
  top: 0;
  left: 0;
  z-index: 16;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

}


.LoadingModalBox{
  background-color: var(--white);
  padding: 30px;
  border-radius: 12px;
  color: var(--primary-default);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  width: 90%;
  margin: 0px 20px;
  box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.1), /* Sombra superior */
            0px 2px 10px rgba(0, 0, 0, 0.1); /* Sombra inferior */
}



.MessageText{
  display:flex;
  flex-direction: row;
  gap: 3px;
  padding: 8px 12px 8px 12px;
  gap: 8px;
  border-radius: 8px;
  background-color: var(--secondary-bronze-lighter);
}

.LoginSideText{
  width: 75%;
  display: flex;
  flex-direction: column;
}


.EditPostInputs{
  display: flex;
  flex-direction: column;
  gap: 16px;
}



.ErrorText{
  padding: 8px 12px 8px 12px;
  gap: 8px;
  border-radius: 8px;
  background: var(--secondary-berry-lighter, #F7E5E9);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: var(--secondary-berry-dark, #B33553);
}

.ErrorBorder{
  border: 1px solid var(--secondary-berry-dark) !important;
}

.MagazineCarouselContainer{
  max-width: 600px;
  overflow:"hidden";
}


.CodeInput{
  display: flex;
  gap: 10px;
}


.CodeInput .LoginInput{
  justify-content: center !important;
}

.CodeInput .LoginInput input {
  text-align: center;
  font-size: 20px;
  padding: 1px !important;
}


.ButtonRow{
  display: flex;
  gap: 10px;
}


.EditPhotoBox{
  background-color:#F4F4F4;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.RecomendationMenuV3{
  display: flex;
  flex-direction: column;
  justify-content: center;
  
}

.RecomendationBarTitleV3{
  color: var(--secondary-bronze-dark, #9B896F);
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 20px ;
  letter-spacing: 1.12px;
  text-transform: uppercase;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  padding: 4px, 0px, 4px, 0px

}

.RecomendationBarProducts{
  display: flex;
  flex-wrap: wrap;
  gap:16px;
  justify-content: center;
  padding: 16px;
}

.miniText{
  max-height: 45px;
  overflow: hidden;
}

.PostsBox{
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  padding: 12px 0px;
  justify-content: center;
}


.BenefitsTabs{
  display: flex;
  gap: 10px;
}


.HelpButton {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--grey-grey200);
  border-radius: 12px;
  padding: 20px 12px;
  width: 100%;
  transition: border-color 0.3s, background-color 0.3s;
  text-decoration: none; /* Para quitar subrayado por defecto en los enlaces */
  color: var(--text-secondary);
}

.HelpButton:hover {
  border-color: var(--secondary-bronze-dark); /* Cambia el color del borde al hacer hover */
  background-color: var(--secondary-bronze-light); /* Cambia el color de fondo al hacer hover */
}


.TitleHelp{
padding: 20px 0px 0px 0px;
font-size: 16px;
font-weight: 400;
line-height: 26.56px;
text-align: left;
}

.SubTitleHelp{
  font-size: 15px;
  font-weight: 300;
  line-height: 21px;
  letter-spacing: 0.03em;
  text-align: left;
  width: 100%;
  }

.TextHelp{
font-size: 14px;
font-weight: 300;
line-height: 21px;
letter-spacing: 0.03em;
text-align: left;
width: 100%;
}

.Q1{
  color: var(--secondary-berry-dark);
}


.Q1:hover{
  color: var(--secondary-berry-default);
}

.element{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.SmallButtonGroup{
  display: flex;
  flex-direction: row;
  gap: 4px;
  justify-content: center;
  align-items: center;
}


.LoginText{
  color: var(--white);
  font-size: 16px;
  font-weight: 400;
  line-height: 21.28px;
  letter-spacing: 0.04em;
  text-align: center;
}


.RegisterBox{
  display:flex;
  flex-direction: column;
  justify-content: center;
padding: 12px ;
gap: 12px;
border-radius: 8px ;
background-color: var(--white);
font-family: SF Pro Rounded;
font-size: 16px;
font-weight: 400;
line-height: 21.28px;
letter-spacing: 0.04em;
text-align: center;
color: var(--text-primary);


}

.RegisterContainer{
  height: 100vh;
  width: 100vw;
  background-image: url("../assets/graficas/fondo.png");
  background-size: contain;
  background-repeat: repeat;
  background-position: center center;
  overflow-y: scroll;
  background-color: var(--grey-grey900);
}

.RegisterLayout{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap:20px;
}


.ShowDetailsButton{
  display: flex;
  justify-content: flex-start;
  color: var(--text-tertiary);
  width: 100%;
  font-size : 12px;
}

.RecieptButton{
  font-size: 12px;
  color: var(--secondary-berry-default);
}

.RecieptButton:hover{
  color: var(--secondary-berry-dark);
}

.RecieptButton:visited{
  color: var(--secondary-berry-dark);
}

.rmsc .item-renderer{
  align-items: flex-start !important;
}


@media only screen and (max-width: 780px) {

  input[type="checkbox"] {
    margin-top:2px !important;
    min-width: 11px !important;
    max-width: 11px !important;
  }
  
}


.LoadingCover{
  width: 100vw;
  height: 100vh;
  background-color: var(--grey-grey100); 
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.5s ease; /* Define the transition property */
}

.loadingSVG{
  width: 70px; /* Ancho inicial del SVG */
  height: 70px; /* Alto inicial del SVG */
  fill: transparent;
  stroke-width: 2; /* Grosor del círculo */
  animation: pulse 1.5s ease-in-out infinite; /* Animación */
  background-color: var(--grey-grey800);
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes pulse {
  0% {
    transform: scale(0.8); /* Escala inicial */
  }
  50% {
    transform: scale(1.2); /* Escala máxima */
  }
  100% {
    transform: scale(0.8); /* Escala final */
  }
}

.AlbumHeaderBackground{
  display: flex;
  height: 50px;
  width: 100%;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 56px;
  background-color: white;
  overflow: hidden;
  z-index: 9;
  }
  

.AlbumHeader{
  max-width: 480px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}


.AlbumTitle{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 20px;
}

.AlbumUserTitle{
  font-size: 12px;
  margin-top: -6px;
}


.RewardsSmallTitle{
  display:flex;
  flex-direction: row;
  font-size:10px;
  justify-content: space-between;
  color: var(--grey-grey500);
}

.CenteredTitle{
  width: 100%;
  text-align: center;
  font-size: 35px;
  font-weight: bold;
  text-transform: uppercase;
  color: var(--secondary-bronze-dark);
  letter-spacing: 3px;
  margin-top: 20px;
}



.progress-container {
  position: relative;
  width: 100%;
  height: 8px;
  background-color: #e0e0e0;
  border-radius: 15px;
  margin-top: 20px;
  margin-bottom: 70px;
}

.FAQRewards{
  display: flex;
  flex-direction: column;
  gap:10px;
  margin: 50px 0px;
}

.markers {
  position: absolute;
  top: 0px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}


.markerdots{
  height:12px;
  width:12px;
  background-color: white;
  border: 2px solid var(--secondary-bronze-dark);
  border-radius:20px;
  margin-top: -2px;
}

.markers div {
  position: absolute;
  font-size: 10px;
  color: black;
  transform: translateX(-50%);
}

.RewardsText{
  top:15px;
}

.markers div span {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--grey-grey500);
}

.RewardsBox{
  width: 90%;
}

.RewardsProgress{
  height:8px;
  background:var(--secondary-bronze-dark);
  transform:translateX(0%) !important;
  border-radius: 15px ;
}


.RecentActivityBox{
  display: flex;
  flex-direction: column;
  gap:10px;
}


.ActividadRecienteBox{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0px;
}

.ActividadRecienteText{
  display: flex;
  flex-direction: column;
  font-size: 12px;
  max-width: 60%;
}

.ActividadRecientePoints{
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 18px;
  font-weight: bold;
  color: var(--secondary-bronze-dark);
  text-align: right;
}

.PointsNumber{
  font-size: 30px;
  line-height: 50%;
}


.CounterWarningText{
  color: var(--secondary-berry-dark);
}



.grey-scale-img {
  filter: grayscale(100%);
  opacity: 0.3;
}


.FilterOptionsMenu form{
  width:100%;
}